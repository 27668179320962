

















import { ref, defineComponent, computed } from '@vue/composition-api';
import { getImageDimensions } from '@/utils';
import useUploadFile from '@/utils/uses/uploadFile/useUploadFile';
export default {
	props: {
		content: {
			type: String,
			default: '',
		},
		underline: {
			type: Boolean,
			default: false,
		},
		iconLeft: {
			type: Boolean,
			default: false,
		},
		changeFileUpload: {
			type: Function,
		},
	},
	setup(props: any, ctx: any) {
		const handlerChangeFileUpload = (event: any) => {
			const files = event.target.files;
			useUploadFile(files).then(response => {
				ctx.emit('changeFileUpload', response);
			});
		};
		return {
			handlerChangeFileUpload,
		};
	},
};
